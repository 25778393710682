<script lang="ts">
  import Repeat from "lucide-svelte/icons/repeat";
  import { Image } from "@/components/ui/image";
  import { Typography } from "@/components/ui/typography";
  import {
    accountsPerSubscription,
    levelsDetails,
    type Level
  } from "@/games/lol/account_info";
  import { cn } from "@/helpers/tw-utils";
  import CheckIcon from "@/images/check.svg";
  import i18n from "@/stores/i18n";

  const t = (key: string, options?: any) =>
    $i18n.t(key, { ns: "accounts", ...options }) as unknown as string;

  let {
    class: className = "",
    level = "30",
    isSubscription = false,
    variant = "p",
    itemClass = ""
  }: Props = $props();

  let levelDetails = levelsDetails[level];

  interface Props {
    class?: string;
    level?: Level;
    isSubscription?: boolean;
    variant?: "p";
    itemClass?: string;
  }

  let itemBaseClass = "flex gap-2 items-center";
</script>

<div class={cn("grid gap-4", className)}>
  <div class={cn(itemBaseClass, itemClass)}>
    <Image
      src={CheckIcon}
      alt={t("badges.blue_essence.title")}
      class="h-4 w-4" />
    <Typography weight="medium" {variant}>
      {levelDetails?.estimated}
      <Typography weight="medium" {variant} as="span" color="muted">
        {t("badges.blue_essence.title")}
        {levelDetails?.pure}
        {t("badges.blue_essence.pure")} - {levelDetails?.loot}
        {t("badges.blue_essence.loot")} ({t("badges.blue_essence.estimated")})
      </Typography>
    </Typography>
  </div>

  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt={t("badges.honor.title")} class="h-4 w-4" />
    <Typography weight="medium" {variant}>
      {t("badges.honor.level")}
      <Typography weight="medium" {variant} as="span" color="muted">
        {t("badges.honor.title")}
      </Typography>
    </Typography>
  </div>

  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt={t("badges.warranty.title")} class="h-4 w-4" />
    <Typography {variant} color="muted" class="!text-green-500">
      {t("badges.warranty.title")}
    </Typography>
  </div>

  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt={t("badges.email.title")} class="h-4 w-4" />
    <Typography weight="medium" {variant} color="muted">
      {t("badges.email.title")}
    </Typography>
  </div>

  <div class={cn(itemBaseClass, itemClass)}>
    <Image src={CheckIcon} alt={t("badges.delivery.title")} class="h-4 w-4" />
    <Typography weight="medium" {variant} color="muted">
      {t("badges.delivery.title")}
    </Typography>
  </div>

  {#if isSubscription}
    <div class={cn(itemBaseClass, itemClass)}>
      <Repeat class="h-8 w-8 text-slate-200" />
      <Typography weight="medium" {variant} color="muted">
        {t("badges.subscription.up_to")}
        <Typography weight="medium" {variant} as="span">
          {accountsPerSubscription}
        </Typography>
        {t("badges.subscription.accounts")} ({t("badges.subscription.every")}
        <Typography weight="medium" {variant} as="span">
          {30 / accountsPerSubscription}
        </Typography>
        {t("badges.subscription.days")})
      </Typography>
    </div>
  {/if}
</div>
